import React, { useState } from 'react';

const ApplicationContext = React.createContext();


const ApplicationProvider = ({ children, isAuth }) => {

 const getNsfw = () => {
  return localStorage.getItem('gravureBackupNsfw');
 }

 const [activeView, setActiveView] = useState("home")
 const [nsfw, setNsfw] = useState(getNsfw());

 const spreadHelper = {
  activeView, setActiveView, isAuth, nsfw, setNsfw
 }
 return (
  <ApplicationContext.Provider value={{ ...spreadHelper }}>
   {children}
  </ApplicationContext.Provider>
 )
}

const ApplicationConsumer = ApplicationContext.Consumer;

export { ApplicationProvider, ApplicationConsumer, ApplicationContext }