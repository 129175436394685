import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseApp = firebase.initializeApp({
 apiKey: "AIzaSyCe4zzIDmuCyfNjwDbMhwes4Ude1O8bjS8",
 authDomain: "gravure-backup.firebaseapp.com",
 databaseURL: "https://gravure-backup.firebaseio.com",
 projectId: "gravure-backup",
 storageBucket: "gravure-backup.appspot.com",
 messagingSenderId: "775349614378",
 appId: "1:775349614378:web:cab896942a649ab18d9053"
});

const db = firebaseApp.firestore();
const auth = firebaseApp.auth();

export { db, auth };
