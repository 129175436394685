import React, { useContext, Suspense, lazy, useState, useEffect } from 'react';
import { ApplicationProvider, ApplicationContext } from '../utils/ApplicationState';
import { auth } from '../utils/Firebase';
import './App.css';

const Navigation = () => {
  const app = useContext(ApplicationContext);

  const clickButton = (value) => {
    app.setActiveView(value);
  }

  return (
    <div className="nav">
      <div className={`nav-button`}>
        <div style={{ padding: "12px" }}>
          <button type="button" className="nes-btn is-primary jp-text" onClick={() => clickButton("home")}>グラビアアーカイブ</button>
        </div>
        <div style={{ padding: "12px" }}>
          <button type="button" className="nes-btn is-success jp-text" onClick={() => clickButton("idol")}>アイドル</button>
        </div>
        <div style={{ padding: "12px" }}>
          <button type="button" className="nes-btn is-warning jp-text" onClick={() => clickButton("ero")}>エロ</button>
        </div>
        <div style={{ padding: "12px" }}>
          <button type="button" className="nes-btn is-normal jp-text" onClick={() => clickButton("favo")}>お気に入り</button>
        </div>
      </div>
    </div>
  )
}

const Footer = () => {
  const app = useContext(ApplicationContext);

  const clickButton = (value) => {
    app.setActiveView(value);
  }
  return (
    <React.Fragment>
      <i className="nes-icon is-large heart"></i>
      <p onClick={() => clickButton("admin")}>2019</p>
    </React.Fragment>
  )
}

const Router = () => {
  const app = useContext(ApplicationContext);

  const Home = lazy(() => import('./Home'));
  const Idols = lazy(() => import('./Idols'));
  const Eros = lazy(() => import('./Eros'));
  const Favo = lazy(() => import('./Favo'));
  const Admin = lazy(() => import('./Admin'));

  const Route = ({ value }) => {
    switch (value) {
      case "home":
        return <Home />;
      case "idol":
        return <Idols />;
      case "ero":
        return <Eros />;
      case "favo":
        return <Favo />;
      case "admin":
        return <Admin />;
      default:
        return <Home />;
    }
  }

  return (
    <div className="pusher">
      <Suspense fallback={<div style={{ textAlign: "center" }}>Loading...</div>}>
        <Route value={app.activeView} />
      </Suspense>
    </div>
  )
}

const App = () => {
  const [isAuth, setIsAuth] = useState({});
  useEffect(
    () => {
      auth.onAuthStateChanged((user) => {
        setIsAuth(user);
      });
    }, []
  );
  return (
    <div className="apps">
      <ApplicationProvider isAuth={isAuth}>
        <Navigation />
        <Router />
        <Footer />
      </ApplicationProvider>
    </div>
  )
}

export default App;
